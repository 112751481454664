export const TOTALPRODUCTS = 'TOTALPRODUCTS'
export const REQUEST_PAYLOAD = 'REQUEST_PAYLOAD'
export const HASNEXT = 'HASNEXT'
export const RESPONSE_PAYLOAD = 'RESPONSE_PAYLOAD'
export const LASTPAGE = 'LASTPAGE'
export const PERPAGE = 'PERPAGE'
export const SETPAGE = 'SETPAGE'
export const SORTING_FIELD = 'SORTING_FIELD';
export const SORTING_DIRECTION = 'SORTING_DIRECTION';

export const PAGING = 'PAGING'
export const CURRENT_PAGE_START_DATE = 'CURRENT_PAGE_START_DATE'
export const CURRENT_PAGE_START_MUID = 'CURRENT_PAGE_START_MUID'
export const CURRENT_PAGE_START_PRODUCTID = 'CURRENT_PAGE_START_PRODUCTID'

export const EXTRA_DATA = 'EXTRA_DATA'
export const TOT_PAGES = 'TOT_PAGES'

export const PRODUCT_ID = 'PRODUCT_ID'
export const PRODUCT_NAME = 'PRODUCT_NAME'
export const STATES = 'STATES'
export const USERS = 'USERS'
export const COUNTRY = 'COUNTRY'
export const IS_SEARCH = 'IS_SEARCH'

export const DESSERTS = 'DESSERTS'
export const TOTALDESSERTS = 'TOTALDESSERTS'
export const TOTRECORDS = 'TOTRECORDS'
export const PAGECOUNT = 'PAGECOUNT'

export const RESET_SEARCH = 'RESET_SEARCH'

export const COMPANY_NAMES = 'COMPANY_NAMES'
export const OCR = 'OCR'

export const PRODUCT_STATUS = 'PRODUCT_STATUS'
export const SECTORS = 'SECTORS'
export const MEDIA_CHANNELS = 'MEDIA_CHANNELS'
export const SORTING = 'SORTING'
export const PAGING_OBJ = 'PAGING_OBJ'

export const MUST_NOT = 'MUST_NOT'

export const ADD_COMPANY = 'ADD_COMPANY'
export const REMOVE_COMPANY = 'REMOVE_COMPANY'

export const MUID = 'MUID'
export const PANELIST = 'PANELIST'
export const ENTRY_ID = 'ENTRY_ID'

export const DM_TM_SOURCE = 'DM_TM_SOURCE'

export const ALLOWED_SECTORS = 'ALLOWED_SECTORS'
