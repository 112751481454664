import * as types from './mutations-type'

export const setStartTime = ({commit}, payload) => {
    commit(types.STARTTIME, payload)
}

export const setHasNext = ({commit}, payload) => {
    commit(types.HASNEXT, payload)
}

export const setRequestPayload = ({commit}, payload) => {
    commit(types.REQUEST_PAYLOAD, payload)
}

export const setTotalDeduplicates = ({commit}, payload) => {
    commit(types.TOTAL_DEDUPLICATES, payload)
}

export const setResponsePayload = ({commit}, payload) => {
    commit(types.RESPONSE_PAYLOAD, payload)
}

export const setLastPage = ({commit}, payload) => {
    commit(types.LASTPAGE, payload)
}

export const setPerPage = ({commit}, payload) => {
    commit(types.PERPAGE, payload)
}

export const setPage = ({commit}, payload) => {
    commit(types.SETPAGE, payload)
}

export const setEndTime = ({commit}, payload) => {
    commit(types.ENDTIME, payload)
}

export const setIsFromSearch = ({commit}, payload) => {
    commit(types.ISFROMSEARCH, payload)
}

export const setIsLoading = ({commit}, payload) => {
    commit(types.ISLOADING, payload)
}

export const setPageObjects = ({commit}, payload) => {
    commit(types.PAGING, payload)
}

export const setDuplicateForId = ({commit}, payload) => {
    commit(types.DUPLICATE_FOR_ID, payload)
}

export const setOriginalHtml = ({commit}, payload) => {
    commit(types.ORIGINAL_HTML, payload)
}

export const setSearchQuery = ({commit}, payload) => {
    commit(types.SEARCH_QUERY, payload)
}

export const setSearchById = ({commit}, payload) => {
    commit(types.SEARCH_BY_ID, payload)
}

export const setSetSearchBySubject = ({commit}, payload) => {
    commit(types.SEARCH_BY_SUBJECT, payload)
}
