import axios from 'axios'
import utils from './utility'
import url from './url'
import VueCookies from 'vue-cookies'

export default ({ requiresAuth = false, operation = null, vueScope = null } = {}) => {
  const options = {
    baseURL: ``,
    headers: {},
  };
  options.baseURL = '';
  if (requiresAuth) {
    let token = utils.getAccessToken()
    if(vueScope){
      let current_active_module = vueScope.$store.getters["Rawdata/getCurrentActiveModule"]
        if(current_active_module){
          options.headers.moduleid = current_active_module.module_id
        }
    }
    let user_id = utils.getCookies('user_id')
    if(user_id){
      options.headers.userid = user_id
    }
    let role_id = utils.getCookies('user_role')
    if(role_id){
      options.headers.roleid = role_id
    }
    if(operation){
      options.headers.operationid = operation
    }
    options.headers.Authorization = token
  }
  const instance = axios.create(options);
  // Response interceptor for API calls
  instance.interceptors.response.use(response => {
    return response;
  }, error => {
    console.log(error)
    if(vueScope){
      vueScope.isLoading = false;
    }
    if (error.response.status === 401) {
      console.log('User Unauthorized');
      VueCookies.remove('access_token', null, url('DOMAIN_NAME'))
      VueCookies.remove('portal_access', null, url('DOMAIN_NAME'))
      VueCookies.remove('email',null, url('DOMAIN_NAME'))
      VueCookies.remove('username',null, url('DOMAIN_NAME'))
      VueCookies.remove('user_id',null, url('DOMAIN_NAME'))
      VueCookies.remove('un_pass',null, url('DOMAIN_NAME'))
      VueCookies.remove('refresh_token',null, url('DOMAIN_NAME'))
      VueCookies.remove('portal_access',null, url('DOMAIN_NAME'))
      VueCookies.remove('token_expire_time',null, url('DOMAIN_NAME'))
      VueCookies.remove('startTime',null, url('DOMAIN_NAME'))
      VueCookies.remove('precedence_url',null, url('DOMAIN_NAME'))
      VueCookies.remove('user_role',null, url('DOMAIN_NAME'))
      if (confirm("Your session has expired, please login again")) {
        location.href = url('SIGNIN_URL')
      } else {
        location.href = url('SIGNIN_URL')
      }
    } else if (error.response.status === 403) {
      alert("You are not authorized for this operation!")
      if(vueScope){
        vueScope.isLoading = false;
      }
    }
    return Promise.reject(error);
  });

  return instance;
};
