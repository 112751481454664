import * as types from './mutations-type'

export const setTotalproducts = ({commit}, payload) => {
    commit(types.TOTALPRODUCTS, payload)
}
export const setRequest_payload = ({commit}, payload) => {
    commit(types.REQUEST_PAYLOAD, payload)
}
export const setHasnext = ({commit}, payload) => {
    commit(types.HASNEXT, payload)
}
export const setResponse_payload = ({commit}, payload) => {
    commit(types.RESPONSE_PAYLOAD, payload)
}
export const setLastPage = ({commit}, payload) => {
    commit(types.LASTPAGE, payload)
}
export const setPerPage = ({commit}, payload) => {
    commit(types.PERPAGE, payload)
}
export const setPage = ({commit}, payload) => {
    commit(types.SETPAGE, payload)
}
export const setSorting_field = ({commit}, payload) => {
    commit(types.SORTING_FIELD, payload)
}
export const setSorting_direction = ({commit}, payload) => {
    commit(types.SORTING_DIRECTION, payload)
}
export const setPaging = ({commit}, payload) => {
    commit(types.PAGING, payload)
}
export const setCurrentPageStartDate = ({commit}, payload) => {
    commit(types.CURRENT_PAGE_START_DATE, payload)
}
export const setCurrentPageStartMuid = ({commit}, payload) => {
    commit(types.CURRENT_PAGE_START_MUID, payload)
}
export const setCurrentPageStartProductId = ({commit}, payload) => {
    commit(types.CURRENT_PAGE_START_PRODUCTID, payload)
}

export const setExtraData = ({commit}, payload) => {
    commit(types.EXTRA_DATA, payload)
}

export const setTotProducts = ({commit}, payload) => {
    commit(types.TOTALPRODUCTS, payload)
}

export const setProductId = ({commit}, payload) => {
    commit(types.PRODUCT_ID, payload)
}

export const setProductName = ({commit}, payload) => {
    commit(types.PRODUCT_NAME, payload)
}

export const setStates = ({commit}, payload) => {
    commit(types.STATES, payload)
}

export const setUsers = ({commit}, payload) => {
    commit(types.USERS, payload)
}

export const setCountry = ({commit}, payload) => {
    commit(types.COUNTRY, payload)
}

export const setIsSearch = ({commit}, payload) => {
    commit(types.IS_SEARCH, payload)
}

export const setDesserts = ({commit}, payload) => {
    commit(types.DESSERTS, payload)
}

export const setTotalDesserts = ({commit}, payload) => {
    commit(types.TOTALDESSERTS, payload)
}

export const setTotRecords = ({commit}, payload) => {
    commit(types.TOTRECORDS, payload)
}

export const setPageCount = ({commit}, payload) => {
    commit(types.PAGECOUNT, payload)
}

export const resetSearch = ({commit}) => {
    commit(types.RESET_SEARCH)
}

export const setOcr = ({commit}, payload) => {
    commit(types.OCR, payload)
}

export const setProductStatus = ({commit}, payload) => {
    commit(types.PRODUCT_STATUS, payload)
}

export const setSectors = ({commit}, payload) => {
    commit(types.SECTORS, payload)
}

export const setMediaChannels = ({commit}, payload) => {
    commit(types.MEDIA_CHANNELS, payload)
}

export const setSorting = ({commit}, payload) => {
    commit(types.SORTING, payload)
}

export const setPagingObj = ({commit}, payload) => {
    commit(types.PAGING_OBJ, payload)
}

export const setMustNot = ({commit}, payload) => {
    commit(types.MUST_NOT, payload)
}

export const addCompany = ({commit}, payload) => {
    commit(types.ADD_COMPANY, payload)
}

export const removeCompany = ({commit}, payload) => {
    commit(types.REMOVE_COMPANY, payload)
}

export const setEntryId = ({commit}, payload) => {
    commit(types.ENTRY_ID, payload)
}

export const setMuid = ({commit}, payload) => {
    commit(types.MUID, payload)
}

export const setPanelist = ({commit}, payload) => {
    commit(types.PANELIST, payload)
}

export const setDmTmSource = ({commit}, payload) => {
    commit(types.DM_TM_SOURCE, payload)
}

export const setAllowedSectors =  ({commit}, payload) => {
    commit(types.ALLOWED_SECTORS, payload)
}