import * as types from './mutations-type'

const mutations = {
    [types.SIDEBAR](state, payload) {
        state.sidebar = payload
    },
    [types.BAR_IMAGE](state, payload) {
      state.barImage = payload
    },
    [types.DRAWER](state, payload) {
        state.drawer = payload
    },
    [types.CURRENT_ACTIVE_MODULE](state, payload) {
        state.current_active_module = payload
    },
    [types.USER_ID](state, payload) {
        state.user_id = payload
    },
    [types.STATE_DATA](state, payload) {
        state.states = payload
    },
    [types.USER_DATA](state, payload) {
        state.userdata = payload
    },
    [types.PRODUCT_STATUS](state, payload) {
        state.product_status = payload
    },
    [types.SECTORS](state, payload) {
        state.sectors = payload
    },[types.CONTENT_SITE_MODULES](state, payload) {
        state.content_site_modules = payload
    },[types.ASSIGNEE_ID](state, payload) {
        state.assignee_id = payload
    },[types.ADMIN_SITE_MODULES](state, payload) {
        state.admin_site_modules = payload
    }
}

export default mutations
