import _ from 'lodash'
import VueCookies from 'vue-cookies'

const utils = {
    getAuthToken: function () {
        try {
            let tok = this.getCookies('access_token')
            return tok
        } catch(e) {
            console.log(e)
            return false
        }
    },
    setCookies: function (key, value, view) {
        VueCookies.set(key, value, null, null, view.$url('DOMAIN_NAME'))
    },
    getCookies: function (key) {
        return VueCookies.get(key)
    },
    removeCookies: function (key, view) {
        return VueCookies.remove(key, null, view.$url('DOMAIN_NAME'))
    },
    checkIfOperationExistForModule(view, type){
      let res = false
      let current_active_module = view.$store.getters["Rawdata/getCurrentActiveModule"]
      if(current_active_module && current_active_module.operations.length){
        current_active_module.operations.forEach(operation => {
          if(operation.operation_name.toLowerCase() === type){
            res = true
          }
        })
      }
      return res
    },
    isValidToken: function() {
        try {
            var token = this.getCookies('access_token')
            if(token !== null && token !=='undefined'){
                return true;
            } else {
                return false;
            }
        } catch (e) {
            console.log(e)
            return false
        }
    },
    isSignedIn: function () {
        var loginFlag = this.getCookies('isSignedIn')
        if (loginFlag !== null && loginFlag !== 'undefined' && loginFlag === "true") {
            return true;
        } else {
            return false;
        }
    },
    getAccessToken: function () {
        let token;
        try{
            token = this.getCookies('access_token')
            if (token !== 'undefined' && token !== null){
                token = this.getCookies('access_token')
            }else{
                token = ""
            }
        }catch(e){
            console.log(e)
            return false
        }
        return token
    },
    // isSigninPage: function () {
    //     if (this.$router.this.$router.currentRoute.name == 'signin')
    //         return true
    //     else
    //         return false

    // // },
    checkisSignedin: function () {
        if (this.getCookies('access_token'))
            return true
        else
            return false
    },
    authenticate: function () {
        let issignin = false
        if (this.getCookies('access_token'))
            issignin = true
        if (issignin == false)
            view.$router.push({ name: 'signin' })
    },
    isLoggedIn:function(){
        let token = this.getCookies('access_token')
        if(token)
            return true
        else
            return false

    },

    getPages: function (lastpage, currentpage, totpage) {
        var arr = new Array(),
            start = parseInt(1),
            end = parseInt(lastpage), i;
        if (parseInt(totpage) > parseInt(10)) {
            if (parseInt(currentpage) <= 4) {
                end = 9
            } else if (parseInt(currentpage) == parseInt(lastpage)) {
                end = lastpage
                start = parseInt(totpage) > 10 ? (parseInt(lastpage) - 9) : parseInt(lastpage / 10) * 10 + 1
            } else if (parseInt(currentpage) >= parseInt(5)) {
                start = currentpage - 4
                if (parseInt(lastpage) - parseInt(currentpage) <= 4) {
                    end = lastpage
                } else {
                    end = currentpage + 4
                }
            }
        }
        for (i = start; i <= end; i++) {
            arr.push(i)
        }
        return arr
    },
    getBaseURL: function () {
        var view = this
        return window.location.origin + "/"
    },
    checkVal: function(v) {
        return (v === '' || v === null || typeof(v) === 'undefined') ? '--' : v
    },
    formateDate: function(d) {
        var d = new Date(d),
            ampm = this.formatAMPM(d),
            month = d.getMonth() + 1,
            day = d.getDate(),
            year = d.getFullYear()
        return ((month < 10 ? '0' + month : month) + '/' +( day < 10 ? '0' + day : day) + '/' + year + ' ' + ampm).toUpperCase()
    },
    formatAMPM: function(date) {
        /**
         * https://stackoverflow.com/questions/8888491/how-do-you-display-javascript-datetime-in-12-hour-am-pm-format
         */
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0'+minutes : minutes;
        var strTime = hours + ':' + minutes + ' ' + ampm;
        return strTime;
    },
    getData(api,view,page){
        if(typeof page !== undefined && page != "" )
            api = api
        return view.$fetch({ requiresAuth: true, operation : 'view', vueScope: view  })
        .get(api)
    }
    ,
    postData(api,view,data){
        return view.$fetch({ requiresAuth: true, operation : 'view', vueScope: view })
        .post(api, data)
    },
    deleteData(api,view){
        return view.$fetch({ requiresAuth: true, operation : 'view', vueScope: view  })
        .delete(api)

    },
    checkIfModuleExist(view,module_name){
        let modules = view.$store.getters["Rawdata/getAdminSiteModules"]
        for (let i = 0; i < modules.length; i++) {
            if (modules[i].module_name.toLowerCase() === module_name.toLowerCase()) {
              return true;
            }
          }
        return false;
    }
}

export default utils
