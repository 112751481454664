import * as types from './mutations-type'

export const setSidebar = ({commit}, payload) => {
    commit(types.SIDEBAR, payload)
}

export const setBarImage = ({commit}, payload) => {
    commit(types.BAR_IMAGE, payload)
}

export const setDrawer = ({commit}, payload) => {
    commit(types.DRAWER, payload)
}

export const setCurrentActiveModule = ({commit}, payload) => {
    commit(types.CURRENT_ACTIVE_MODULE, payload)
}

export const setUserId = ({commit}, payload) => {
    commit(types.USER_ID, payload)
}

export const setUserData = ({commit}, payload) => {
    commit(types.USER_DATA, payload)
}

export const setStateData = ({commit}, payload) => {
    commit(types.STATE_DATA, payload)
}

export const setProductData = ({commit}, payload) => {
    commit(types.PRODUCT_STATUS, payload)
}

export const setSectors = ({commit}, payload) => {
    commit(types.SECTORS, payload)
}
export const setContentSiteModules = ({commit}, payload) => {
    commit(types.CONTENT_SITE_MODULES, payload)
}
export const setAssigneeID = ({commit}, payload) => {
    commit(types.ASSIGNEE_ID, payload)
}
export const setAdminSiteModules = ({commit}, payload) => {
    commit(types.ADMIN_SITE_MODULES, payload)
}
