const state = {
    requestPayload: {
        page: 1,
        perpage: 10,
        totproducts: '',
        current_page_start_date: '',
        current_page_start_muid: '',
        current_page_start_productid: '',
    },
    paging: [],
    responsePayload: '',
    lastpage: 0,
    extraData: '',
    totpages: 0,
    searchRequest: {
        product_id: '',
        product_name: '',
        companies: [],
        states: '',
        users: '',
        country: 'all',
        ocr: '',
        product_status: [],
        sectors: [],
        media_channels: ["DIRECT_MAIL"],
        sorting: [],
        paging_obj: {},
        must_not: [],
        muid: '',
        entry_id: '',
        panelist: '',
        dmtmsource: '',
        allowed_sectors:''
    },
    isSearch: false,
    desserts: [],
    totalDesserts: 0,
    totRecords: 0,
    pageCount: 0,
    company_names: '',
}

export default state
