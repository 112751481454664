const state = {
    isEdit: false,
    requestPayload: {
        page: 1,
        perpage: 10,
        tot_set: ''
    },
    lastpage: 0,
    hasnext: false,
    responsePayload: [],
    start_time: 0,
    end_time: 0,
    isfromsearch: false,
    isloading: false,
    paging: '',
    duplicate_html: '',
    original_html: '',
    searchRequestPayload: {
        query: '',
        searchBy: {
            id: true,
            subject: false
        }
    },

}

export default state
