export const getTotalProducts = (state) => {
    return state.requestPayload.totalproducts
}
export const getRequestPayload = (state) => {
    return state.requestPayload
}

export const getresponse_payload = (state) => {
    return state.responsePayload
}

export const getLastPage = (state) => {
    return state.lastpage
}
export const getPerPage = (state) => {
    return state.requestPayload.perpage
}
export const getPage = (state) => {
    return state.requestPayload.page
}

export const getPaging = (state) => {
    return state.paging
}

export const getcurrent_page_from_date = (state) => {
    return state.requestPayload.current_page_from_date
}

export const getcurrent_page_from_muid = (state) => {
    return state.requestPayload.current_page_from_date
}

export const getcurrent_page_from_productid = (state) => {
    return state.requestPayload.current_page_from_date
}

export const getExtraData = (state) => {
    return state.extraData
}

export const getTempId = (state) => {
    return state.searchRequest.temp_id
}

export const getCompanyName = (state) => {
    return state.searchRequest.company_name
}

export const getIsSearch = (state) => {
    return state.isSearch
}

export const getDesserts = (state) => {
    return state.desserts
}

export const getTotalDesserts = (state) => {
    return state.totalDesserts
}

export const getTotRecords = (state) => {
    return state.totRecords
}

export const getPageCount = (state) => {
    return state.pageCount
}

export const getSearch = (state) => {
    return state.searchRequest
}

export const getCompanyNames = (state) => {
    return state.company_names
}

export const getProductId = (state) => {
    return state.searchRequest.product_id
}

export const getProductName = (state) => {
    return state.searchRequest.product_name
}

export const getCompanies = (state) => {
    return state.searchRequest.companies
}

export const getStates = (state) => {
    return state.searchRequest.states
}

export const getUsers = (state) => {
    return state.searchRequest.users
}

export const getCountry = (state) => {
    return state.searchRequest.country
}

export const getOcr = (state) => {
    return state.searchRequest.ocr
}

export const getProductStatus = (state) => {
    return state.searchRequest.product_status
}

export const getSectors = (state) => {
    return state.searchRequest.sectors
}

export const getMediaChannels = (state) => {
    return state.searchRequest.media_channels
}

export const getMuid = (state) => {
    return state.searchRequest.muid
}

export const getEntryId = (state) => {
    return state.searchRequest.entry_id
}

export const getPanelist = (state) => {
    return state.searchRequest.panelist
}

export const getDmTmSource = (state) => {
    return state.searchRequest.dmtmsource
}

export const getAllowedSectors = (state) => {
    return state.searchRequest.allowed_sectors
}

