import * as types from './mutations-type'

const mutations = {
    [types.TOTALPRODUCTS](state, payload) {
        state.requestPayload.totproducts = payload
    },
    [types.REQUEST_PAYLOAD](state, payload) {
        state.request_payload = payload
    },
    [types.HASNEXT](state, payload) {
        state.hasnext = payload
    },
    [types.RESPONSE_PAYLOAD](state, payload) {
        state.response_payload = payload
    },
    [types.LASTPAGE](state, payload) {
        state.lastpage = payload
    },
    [types.PERPAGE](state, payload) {
        state.requestPayload.perpage = payload
    },
    [types.SETPAGE](state, payload) {
        state.requestPayload.page = payload
    },
    [types.SORTING_FIELD](state, payload) {
        state.sorting_field = payload
    },
    [types.SORTING_DIRECTION](state, payload) {
        state.sorting_direction = payload
    },
    [types.PAGING](state, payload) {
        state.paging = payload
    },
    [types.CURRENT_PAGE_START_DATE](state, payload) {
        state.requestPayload.current_page_start_date = payload
    },
    [types.CURRENT_PAGE_START_MUID](state, payload) {
        state.requestPayload.current_page_start_muid = payload
    },
    [types.CURRENT_PAGE_START_PRODUCTID](state, payload) {
        state.requestPayload.current_page_start_productid = payload
    },
    [types.EXTRA_DATA](state, payload) {
        state.extraData = payload
    },
    [types.TOT_PAGES](state, payload) {
        state.totpages = payload
    },
    [types.PRODUCT_ID](state, payload) {
        state.searchRequest.product_id = payload
    },
    [types.PRODUCT_NAME](state, payload) {
        state.searchRequest.product_name = payload
    },
    [types.STATES](state, payload) {
        state.searchRequest.states = payload
    },
    [types.USERS](state, payload) {
        state.searchRequest.users = payload
    },
    [types.COUNTRY](state, payload) {
        state.searchRequest.country = payload
    },
    [types.IS_SEARCH](state, payload) {
        state.isSearch = payload
    },
    [types.DESSERTS](state, payload) {
        state.desserts = payload
    },
    [types.TOTALDESSERTS](state, payload) {
        state.totalDesserts = payload
    },
    [types.TOTRECORDS](state, payload) {
        state.totRecords = payload
    },
    [types.PAGECOUNT](state, payload) {
        state.pageCount = payload
    },
    [types.RESET_SEARCH](state) {
        state.isSearch = false
        state.searchRequest.product_id = ''
        state.searchRequest.product_name = ''
        state.searchRequest.companies = new Array()
        state.searchRequest.states = ''
        state.searchRequest.users = ''
        state.searchRequest.country = 'all'
        state.searchRequest.ocr = ''
        state.searchRequest.product_status = new Array()
        state.searchRequest.sectors = new Array()
        state.searchRequest.media_channels = new Array()
        state.searchRequest.muid = ''
        state.searchRequest.entry_id = ''
        state.searchRequest.dmtmsource = ''
    },
    [types.COMPANY_NAMES](state, payload) {
        state.company_names = payload
    },
    [types.OCR](state, payload) {
        state.searchRequest.ocr = payload
    },
    [types.PRODUCT_STATUS](state, payload) {
        state.searchRequest.product_status = payload
    },
    [types.SECTORS](state, payload) {
        state.searchRequest.sectors = payload
    },
    [types.MEDIA_CHANNELS](state, payload) {
        state.searchRequest.media_channels = payload
    },
    [types.SORTING](state, payload) {
        state.searchRequest.sorting = new Array()
        state.searchRequest.sorting.push(payload)
    },
    [types.PAGING_OBJ](state, payload) {
        state.searchRequest.paging_obj = payload
    },
    [types.ADD_COMPANY](state, payload) {
        state.searchRequest.companies.push(payload)
    },
    [types.REMOVE_COMPANY](state, payload) {
        let companis = state.searchRequest.companies
        state.searchRequest.companies = new Array()
        state.searchRequest.companies = companis.filter(o => payload !== o)
    },
    [types.MUID](state, payload) {
        state.searchRequest.muid = payload
    },
    [types.PANELIST](state, payload) {
        state.searchRequest.panelist = payload
    },
    [types.ENTRY_ID](state, payload) {
        state.searchRequest.entry_id = payload
    },
    [types.DM_TM_SOURCE](state, payload) {
        state.searchRequest.dmtmsource = payload
    },
    [types.ALLOWED_SECTORS](state, payload) {
        state.searchRequest.allowed_sectors = payload
    },
}

export default mutations