const state = {
    sidebar: [],
    current_active_module: null,
    barColor: '#ffffff',
    barImage: 'https://demos.creative-tim.com/material-dashboard/assets/img/sidebar-1.jpg',
    drawer: null,
    user_id: null,
    states: [],
    userdata: [],
    product_status: [],
    sectors:[],
    content_site_modules:[],
    assignee_id:null,
    admin_site_modules:[],
    s3AcessKeys:{
        secretAccessKey : "OfOt1GTd+hR1SvREYV+7LX8siG+VGvtYeIXt1iw5",
        accessKeyId : "AKIAV2BYPTUEDSRKK55F",
        bucket : "csv2-mangefilescontent-development",
        region : "us-west-2",
        path:"v2/file_uploads/"
    },
}

export default state
