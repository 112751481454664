export const getSidebar = (state) => {
    return state.sidebar
}

export const getBarImage = (state) => {
    return state.barImage
}

export const getDrawer = (state) => {
    return state.drawer
}

export const getCurrentActiveModule = (state) => {
    return state.current_active_module
}

export const getUserId = (state) => {
    return state.user_id
}
export const getSectors = (state) => {
    return state.sectors
}

export const getStates = (state) => {
    return state.states
}

export const getUserData = (state) => {
    return state.userdata
}

export const getProductStatus = (state) => {
    return state.product_status
}
export const getContentSiteModules = (state) => {
    return state.content_site_modules
}
export const getAssigneeData = (state) => {
    return state.assignee_id
}
export const getAdminSiteModules = (state) => {
    return state.admin_site_modules
}
export const getS3BucketAccessKeys = (state) => {
    return state.s3AcessKeys
}