export const getTotalDmduplicates = (state) => {
    return state.requestPayload.tot_dmduplicates
}

export const getRequestPayload = (state) => {
 
    return state.requestPayload
}

export const getLastPage = (state) => {
    return state.lastpage
}

export const getHasNext = (state) => {
    return state.hasnext
}

export const getResponsePayload = (state) => {
    
    return state.responsePayload
}

export const getCurrentPage = (state) => {
    return state.requestPayload.page
}

export const getStartTime = (state) => {
    return state.start_time
}

export const getEndTime = (state) => {
    return state.end_time
}

export const getPerPage = (state) => {
    return state.requestPayload.perpage
}

export const getIsFromSearch = (state) => {
    return state.isfromsearch
}

export const getIsLoading = (state) => {
    return state.isloading
}

export const getPaging = (state) => {
    return state.paging
}

export const getDuplicateForId = (state) => {
    return state.duplicate_html
}
export const getOriginalHtml = (state) => {
    return state.original_html
}

export const getSearchQuery = (state) => {
    return state.searchRequestPayload.query
}

export const getSearchById = (state) => {
    return state.searchRequestPayload.searchBy.id
}

export const setSetSearchBySubject = (state) => {
    return state.searchRequestPayload.searchBy.subject
}

export const gerSearchPayload = (state) => {
    return state.searchRequestPayload
}
