import * as types from './mutations-type'

const mutations = {
    [types.ISEDIT](state, payload) {
        state.isEdit = payload
    },
    [types.HASNEXT](state, payload) {
        state.hasnext = payload
    },
    [types.TOTAL_SET](state, payload) {
        state.requestPayload.tot_set = payload
    },
    [types.LASTPAGE](state, payload) {
        state.lastpage = payload
    },
    [types.REQUEST_PAYLOAD](state, payload) {
        state.requestPayload = payload
    },
    [types.RESPONSE_PAYLOAD](state, payload) {
        state.responsePayload = payload
    },
    [types.PERPAGE](state, payload) {
        state.requestPayload.perpage = payload
    },
    [types.SETPAGE](state, payload) {
        state.requestPayload.page = payload
    },
    [types.STARTTIME](state, payload) {
        state.start_time = payload
    },
    [types.ENDTIME](state, payload) {
        state.end_time = payload
    },
    [types.ISFROMSEARCH](state, payload) {
        state.isfromsearch = payload
    },
    [types.ISLOADING](state, payload) {
        state.isloading = payload
    },
    [types.DUPLICATE_FOR_ID](state, payload) {
        state.duplicate_html = payload
    },
    [types.ORIGINAL_HTML](state, payload) {
        state.original_html = payload
    },
    [types.SEARCH_QUERY](state, payload) {
        state.searchRequestPayload.query = payload
    },
    [types.SEARCH_BY_ID](state, payload) {
        state.searchRequestPayload.searchBy.id = payload
    },
    [types.SEARCH_BY_SUBJECT](state, payload) {
        state.searchRequestPayload.searchBy.subject = payload
    },
}

export default mutations
