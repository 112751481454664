export const SIDEBAR = 'SIDEBAR'
export const BAR_IMAGE = 'BAR_IMAGE'
export const DRAWER = 'DRAWER'
export const CURRENT_ACTIVE_MODULE = 'CURRENT_ACTIVE_MODULE'
export const USER_ID = 'USER_ID'

export const STATE_DATA = 'STATE_DATA'
export const USER_DATA = 'USER_DATA'
export const PRODUCT_STATUS = 'PRODUCT_STATUS'
export const SECTORS = 'SECTORS'
export const CONTENT_SITE_MODULES = "CONTENT_SITE_MODULES"
export const ASSIGNEE_ID = "ASSIGNEE_ID"
export const ADMIN_SITE_MODULES = "ADMIN_SITE_MODULES"
